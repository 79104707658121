.horizontal-padding-container {
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 576px) {
  .horizontal-padding-container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media (min-width: 768px) {
  .horizontal-padding-container {
    padding-left: 64px;
    padding-right: 64px;
  }
}
