.album-photo-item {
  display: block;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
  color: inherit;
}

.album-photo-item__image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.album-photo-item__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.album-photo-item__delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.album-photo-item__caption {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0 16px;
}

.album-photo-item__cover-photo-checkbox {
  --size: 16px;
}

.album-photo-item__cover-photo-checkbox-label {
  font-size: 14px !important;
}
