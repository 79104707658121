.login-card-container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-card {
  width: 530px;
  max-width: 90%;
}
