.date-time-input input {
  cursor: pointer;
}

.date-time-input__popover {
  --width: auto;
}

.date-time-input__popover::part(content) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
