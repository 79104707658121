.upload-photo-preview-item {
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
}

.upload-photo-preview-item__image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.upload-photo-preview-item__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.upload-photo-preview-item__delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.upload-photo-preview-item__details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
}

.upload-photo-preview-item__details__name {
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 12px;
}

.upload-photo-preview-item__details__size {
  flex: none;
  margin-left: 4px;
  font-size: 16px;
}

.upload-photo-preview-item__caption-input {
  padding: 0 16px 8px 16px;
}
