/* Used in useProgressDialog hook */
.progress-dialog__popover {
  --backdrop-opacity: 0.3;
}

.progress-dialog__spinner {
  display: block;
  margin-bottom: 8px;
  margin: 0 auto 8px auto;
}

.progress-dialog__label {
  display: block;
  margin-bottom: 16px;
  text-align: center;
}
