.file-drop-zone {
  border: 2px dashed #eeeeee;
  border-radius: 2px;
  background-color: #fafafa;
  outline: none;
  cursor: pointer;
  transition: border .24s ease-in-out;
}

.file-drop-zone:hover,
.file-drop-zone.drag-active {
  border-color: #2196f3;
}

.file-drop-zone.disabled {
  opacity: 0.6;
}

.file-drop-zone__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #bdbdbd;
  user-select: none;
}
