/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
  .images-upload-modal {
    --width: 720px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  .images-upload-modal {
    --width: 920px;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
  .images-upload-modal {
    --width: 1100px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  .images-upload-modal {
    --height: 90%;
  }
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  .images-upload-modal {
    --height: 90%;
  }
}
