/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** Side Menu **/
  /* --side-max-width: 300px;
  --side-width:300px; */
  /** primary **/
  --ion-color-primary: #4b83fb;
  --ion-color-primary-rgb: 100, 144, 153;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #99b5ff;
  --ion-color-primary-tint: #6494fc;

  /** secondary **/
  --ion-color-secondary: #83bcc7;
  --ion-color-secondary-rgb: 100, 144, 153;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #65cfa9;
  --ion-color-success-rgb: 101, 207, 169;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #5aba98;
  --ion-color-success-tint: #74d3b1;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #f67878;
  --ion-color-danger-rgb: 240, 120, 120;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #dd6c6c;
  --ion-color-danger-tint: #f68585;

  /** dark **/
  --ion-color-dark: #6f7688;
  --ion-color-dark-rgb: 111, 118, 136;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #636a7a;
  --ion-color-dark-tint: #7d8393;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-white: white;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #d7d8da;
  --ion-color-white-tint: #f5f6f9;

  --ion-color-processing: #ffc266;
  --ion-color-processing-rgb: 255, 194, 102;
  --ion-color-processing-contrast: white;
  --ion-color-processing-contrast-rgb: 255, 255, 255;
  --ion-color-processing-shade: #e5ae5b;
  --ion-color-processing-tint: #ffc875;

  --ion-background-color: #edf0f2;
  --ion-background-color-rgb: 237, 240, 242;

 
}
