.card-content-ios {
  background: white;
}

.lead-details-card {
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  box-shadow: none;
}
