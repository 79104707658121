.albums-page__item__avatar {
  --border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-light);
}

.albums-page__item__avatar__placeholder {
  margin: auto;
}
