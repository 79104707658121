.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-processing {
  --ion-color-base: var(--ion-color-processing);
  --ion-color-base-rgb: var(--ion-color-processing-rgb);
  --ion-color-contrast: var(--ion-color-processing-contrast);
  --ion-color-contrast-rgb: var(--ion-color-processing-contrast-rgb);
  --ion-color-shade: var(--ion-color-processing-shade);
  --ion-color-tint: var(--ion-color-processing-tint);
}

.no-margin-card {
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  box-shadow: none;
}

.no-margin-h {
  margin-left: 0;
  margin-right: 0;
}
.no-margin-top {
  margin-top: 0;
}

.no-shadow {
  box-shadow: none;
}

.bg-white {
  background-color: white;
}

.split-pane {
  --side-max-width: 300px;
}

.side-menu-list {
  height: 100%;
}

.side-menu-logout-button {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (max-width: 1199px) {
  .side-menu {
    --background: transparent;
  }

  .side-menu-toolbar {
    border-top-right-radius: 15px;
  }

  .side-menu-list {
    border-bottom-right-radius: 15px;
  }

  .split-pane {
    --border: none;
  }
}
