.album-photos-page__cover-photo__img-container{
    min-width: 200px;
    min-height: 200px;
    max-width: 40vh;
    max-height: 40vh;
    margin-left: auto;
    margin-right: auto;
}

.album-photos-page__cover-photo__img-wrapper {
    position: relative;
    width: 100%;
    padding-top: 100%;
}

.album-photos-page__cover-photo__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
